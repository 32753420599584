/* ==================================================================================================================
 * OpenGoSim Bluebell: app/shared/services/websockets.service.ts
 * Copyright 2017-2018 TotalSim Ltd
 * The contents of this file are NOT for redistribution
 * See AUTHORS for list of developers on project
 * ================================================================================================================== */
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { AppStoreUtils } from 'app/store/app.store';
import { ClearAllStoresAction } from 'app/store/app.store.constants';
import { environment } from 'environments/environment';


@Injectable({
  providedIn: 'root',
})
export class WebSocketService {

  private _socket: WebSocket;
  connected$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private store: AppStoreUtils) {}

  public connect(): void {
    if (!this._socket) {
      const protocol: string = !!environment.server ?
        new RegExp('^(\\w+):').exec(environment.server)[1] === 'https' ? 'wss://' : 'ws://'
        :
        location.protocol === 'https:' ? 'wss://' : 'ws://';
      const hostname: string = !!environment.server ?
        new RegExp('//([\\w\.-]*)/?').exec(environment.server)[1]  // extract hostname from full server
        :
        location.hostname;
      const token = this.getJWT();
      const uri: string = protocol + hostname + '/ws/?token=' + token;
      this._socket = this.create(uri);
    }
  }

  public refresh(): void {
    if (!!this._socket) {
      this._socket.close();
      this._socket = undefined;
    }
    this.connect();
  }

  private create(url): WebSocket {
    const ws = new WebSocket(url);
    ws.onclose = () => this.connected$.next(false);
    ws.onerror = (e) => console.log('websocket error:', e);
    ws.onmessage = (message) => this.store.dispatch(JSON.parse(message.data));
    ws.onopen = () => this.connected$.next(true);
    return ws;
  }

  private getJWT(): string {
    const token: string = localStorage.getItem('jwt-token');
    if (!token) {
      this.store.dispatch(new ClearAllStoresAction());
      location.reload();
    }
    return token;
  }
}
